<template>
  <v-dialog v-model="dialog" width="1200" persistent>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col> Manage Assignments </v-col>
          <v-col class="shrink" v-if="!isMobile">
            <PrivateDocumentNewAssignments
              ref="newAssignments"
              :document="document"
              @confirmed="newAssignConfirmed()"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text :class="`mt-2 text-body-1 ${isMobile ? 'px-2' : ''}`">
        <v-row v-if="isMobile">
          <v-col class="pb-6">
            <PrivateDocumentNewAssignments
              ref="newAssignments"
              :document="document"
              @confirmed="newAssignConfirmed()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-if="!document.isPublished">
            <v-alert type="error" outlined dense class="mb-0">
              Document must be published prior to assigning.
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-data-table
                :items="assignedEmployees"
                :headers="headers"
                no-data-text="No assignment"
                :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
              >
                <template v-slot:[`item.createdOnMobileAt`]="{ item }">
                  {{ item.createdOnMobileAt | filterAsLocalDate }}
                </template>
                <template v-slot:[`item.completedAt`]="{ item }">
                  {{ item.completedAt | filterAsLocalDate }}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    :class="statusClass(item.statusText)"
                    :style="{ 'border-radius': '5px', width: '100px' }"
                  >
                    {{ item.statusText }}
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        v-blur
                        text
                        small
                        v-on="{ ...tooltip }"
                        @click="unassign(item.id)"
                        :disabled="
                          item.documentSignOffStatusId ==
                          $constants.ASSIGNED_DOCUMENT_STATUS.SUBMITTED
                        "
                      >
                        <v-icon color="red"> mdi-archive-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Archive</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="dialog = false"
          text
          class="msaBlue--text font-weight-bold"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import PrivateDocumentNewAssignments from '@/components/PrivateDocumentLibrary/PrivateDocumentNewAssignments.vue';
export default {
  name: 'PrivateDocumentManageAssignments',
  props: {
    document: {
      type: Object,
    },
  },
  components: {
    PrivateDocumentNewAssignments,
  },
  computed: {
    statusClass() {
      return (status) => {
        switch (status) {
          case 'Assigned':
            return 'red white--text';
          case 'Submitted':
            return 'green white--text';
          default:
            return '';
        }
      };
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  data() {
    return {
      dialog: false,
      assignedEmployees: [],
      headers: [
        {
          text: 'Name',
          value: 'assignedToName',
          class: 'lightBg',
        },
        {
          text: 'Assigned Date',
          value: 'createdOnMobileAt',
          class: 'lightBg',
          width: '140px',
        },
        {
          text: 'Complete Date',
          value: 'completedAt',
          class: 'lightBg',
          width: '150px',
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          class: 'lightBg',
          width: '110px',
        },
        {
          text: 'Revision',
          value: 'revision',
          align: 'center',
          class: 'lightBg',
          width: '101px',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          class: 'lightBg',
          width: '82px',
        },
      ],
    };
  },
  methods: {
    getAssignedEmployees() {
      const params = {
        loaderText: 'Loading...',
        documentId: this.document.id,
      };
      const url = 'get-private-library-document-assigned-employees?format=json';

      return this.$axios.post(url, params).then((response) => {
        this.assignedEmployees = response.data;
      });
    },
    unassign(assignmentId) {
      const params = {
        loaderText: 'Loading...',
        ids: [assignmentId],
      };
      const url = 'delete-assigned-documents?format=json';

      this.$axios.post(url, params).then(() => {
        this.getAssignedEmployees();
        this.$refs.newAssignments.getEmployeesToAssign();
        this.$emit('reloadList');
      });
    },
    newAssignConfirmed() {
      if (this.isMobile) {
        this.getAssignedEmployees();
      } else {
        this.reloadNewAssignments();
      }

      this.$emit('reloadList');
    },
    show() {
      this.$nextTick(() => {
        this.getAssignedEmployees().then(() => {
          this.dialog = true;
          setTimeout(() => {
            this.$refs.newAssignments.getEmployeesToAssign();
          }, 10);
        });
      });
    },
    reloadNewAssignments() {
      this.getAssignedEmployees().then(() => {
        this.$refs.newAssignments.getEmployeesToAssign();
      });
    },
  },
};
</script>
<style scoped></style>
