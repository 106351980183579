<template>
  <v-expansion-panels v-model="expanded">
    <v-expansion-panel
      v-for="(group, index) in groups"
      :key="index"
      class="mb-4"
    >
      <v-expansion-panel-header
        v-slot="{ open }"
        :class="headerClass(index)"
        :style="headerStyles(index)"
        hide-actions
      >
        <v-row align="center">
          <v-col class="shrink">
            <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
            <v-icon v-else>mdi-plus-thick</v-icon>
          </v-col>
          <v-col :class="(open ? 'white--text' : '') + ' font-weight-medium'">
            {{ group.name }}
            <v-badge
              :bordered="open"
              :content="`${group.documentCount}`"
              class="ml-2"
            ></v-badge>
          </v-col>
          <v-col
            class="shrink px-0"
            v-if="
              open && group.level < 3 && $route.name == 'CompanyDocumentLibrary'
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-blur
                  v-on="on"
                  @click.stop
                  @click="showNewGroupDetails(group)"
                  color="white"
                  small
                  text
                >
                  <v-icon> mdi-folder-plus-outline </v-icon>
                </v-btn>
              </template>
              <span>Add Folder</span>
            </v-tooltip>
          </v-col>
          <v-col
            class="shrink px-0"
            v-if="$route.name == 'CompanyDocumentLibrary'"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-blur
                  v-on="on"
                  :color="open ? 'white' : 'msaBlue'"
                  @click.stop
                  @click="$emit('showGroupDetails', group)"
                  small
                  text
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
          <v-col
            v-if="$route.name == 'CompanyDocumentLibrary'"
            class="shrink px-0"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-blur
                  v-on="on"
                  @click.stop
                  @click="deleteGroup(group.id)"
                  color="red"
                  small
                  text
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pa-4">
        <v-row>
          <v-col
            v-if="
              group.level < 3 &&
              group.groups.length > 1 &&
              canChangeOrders &&
              $route.name == 'CompanyDocumentLibrary'
            "
            align="right"
            cols="12"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-blur
                  v-on="on"
                  @click="$emit('orderFolders', group.groups)"
                  class="msaBlue white--text"
                >
                  <v-icon> mdi-folder-arrow-up-down-outline </v-icon>
                </v-btn>
              </template>
              <span>Order Folders</span>
            </v-tooltip>
          </v-col>
          <v-col
            v-if="group.level < 3 && group.groups.length"
            cols="12"
            class="pb-0"
          >
            <PrivateDocumentGroups
              ref="groups"
              :groups="group.groups"
              :canChangeOrders="canChangeOrders"
              :selected="selected"
              :forUpload="forUpload"
              @showGroupDetails="$emit('showGroupDetails', $event)"
              @deleteGroup="$emit('deleteGroup', $event)"
              @showDocumentDetails="$emit('showDocumentDetails', $event)"
              @reloadList="$emit('reloadList')"
              @showAssignments="$emit('showAssignments', $event)"
              @showLabels="$emit('showLabels', $event)"
              @orderFolders="$emit('orderFolders', $event)"
              @orderDocuments="$emit('orderDocuments', $event)"
              @selected="$emit('selected', $event)"
            />
          </v-col>
          <v-col
            v-if="
              group.documents.length > 1 &&
              canChangeOrders &&
              $route.name == 'CompanyDocumentLibrary'
            "
            align="right"
            cols="12"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-blur
                  v-on="on"
                  @click="$emit('orderDocuments', group.documents)"
                  class="msaBlue white--text"
                >
                  <v-icon> mdi-file-arrow-up-down-outline </v-icon>
                </v-btn>
              </template>
              <span>Order Files</span>
            </v-tooltip>
          </v-col>
          <v-col v-if="group.documents.length && !forUpload" cols="12">
            <PrivateDocumentsList
              :documents="group.documents"
              :canChangeOrders="canChangeOrders"
              :selected="selected"
              @showDocumentDetails="$emit('showDocumentDetails', $event)"
              @reloadList="$emit('reloadList')"
              @showAssignments="$emit('showAssignments', $event)"
              @showLabels="$emit('showLabels', $event)"
              @selected="$emit('selected', $event)"
            />
          </v-col>
        </v-row>

        <div
          v-if="
            group.level < 3 &&
            group.groups.length == 0 &&
            group.documents.length == 0
          "
          class="text-center mt-6"
        >
          (Empty Folder)
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import PrivateDocumentsList from '@/components/PrivateDocumentLibrary/PrivateDocumentsList.vue';
import PrivateDocumentGroups from '@/components/PrivateDocumentLibrary/PrivateDocumentGroups.vue';

export default {
  name: 'PrivateDocumentGroups',
  components: {
    PrivateDocumentsList,
    PrivateDocumentGroups,
  },
  props: {
    groups: {
      type: Array,
    },
    canChangeOrders: {
      type: Boolean,
    },
    selected: {
      type: Array,
    },
    forUpload: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headerStyles() {
      return (index) =>
        index == this.expanded
          ? {}
          : {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            };
    },
    headerClass() {
      return (index) => (index == this.expanded ? 'msaBlue white--text' : '');
    },
  },

  data() {
    return {
      expanded: undefined,
    };
  },
  methods: {
    showNewGroupDetails(parentGroup) {
      const group = {
        id: 0,
        level: parentGroup.level + 1,
        name: '',
        parentPrivateLibraryGroupId: parentGroup.id,
      };
      this.$emit('showGroupDetails', group);
    },
    deleteGroup(id) {
      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to delete this folder? All folders and files under this folder will be deleted.',
        () => {
          this.$emit('deleteGroup', id);
        },
        () => false,
        'Confirm',
        'Cancel',
      );
    },
    resetPanels() {
      this.expanded = undefined;
    },
    updateSelection() {
      // update selected documents props
      this.groups.forEach((g) =>
        g.documents.forEach((d) => {
          const index = this.selected.findIndex(
            (s) => s.id == d.id && JSON.stringify(s) != JSON.stringify(d),
          );

          if (index != -1) {
            this.$emit('selected', { docs: [d], value: true });
          }
        }),
      );
      if (this.$refs.groups) {
        this.$refs.groups.updateSelection();
      }
    },
  },
};
</script>

<style></style>
