var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panels',{model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}},_vm._l((_vm.groups),function(group,index){return _c('v-expansion-panel',{key:index,staticClass:"mb-4"},[_c('v-expansion-panel-header',{class:_vm.headerClass(index),style:(_vm.headerStyles(index)),attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function({ open }){return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"shrink"},[(open)?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-minus-thick")]):_c('v-icon',[_vm._v("mdi-plus-thick")])],1),_c('v-col',{class:(open ? 'white--text' : '') + ' font-weight-medium'},[_vm._v(" "+_vm._s(group.name)+" "),_c('v-badge',{staticClass:"ml-2",attrs:{"bordered":open,"content":`${group.documentCount}`}})],1),(
            open && group.level < 3 && _vm.$route.name == 'CompanyDocumentLibrary'
          )?_c('v-col',{staticClass:"shrink px-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({directives:[{name:"blur",rawName:"v-blur"}],attrs:{"color":"white","small":"","text":""},on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.showNewGroupDetails(group)}]}},on),[_c('v-icon',[_vm._v(" mdi-folder-plus-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Add Folder")])])],1):_vm._e(),(_vm.$route.name == 'CompanyDocumentLibrary')?_c('v-col',{staticClass:"shrink px-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({directives:[{name:"blur",rawName:"v-blur"}],attrs:{"color":open ? 'white' : 'msaBlue',"small":"","text":""},on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.$emit('showGroupDetails', group)}]}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1):_vm._e(),(_vm.$route.name == 'CompanyDocumentLibrary')?_c('v-col',{staticClass:"shrink px-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({directives:[{name:"blur",rawName:"v-blur"}],attrs:{"color":"red","small":"","text":""},on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.deleteGroup(group.id)}]}},on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1):_vm._e()],1)]}}],null,true)}),_c('v-expansion-panel-content',{staticClass:"pa-4"},[_c('v-row',[(
            group.level < 3 &&
            group.groups.length > 1 &&
            _vm.canChangeOrders &&
            _vm.$route.name == 'CompanyDocumentLibrary'
          )?_c('v-col',{attrs:{"align":"right","cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({directives:[{name:"blur",rawName:"v-blur"}],staticClass:"msaBlue white--text",on:{"click":function($event){return _vm.$emit('orderFolders', group.groups)}}},on),[_c('v-icon',[_vm._v(" mdi-folder-arrow-up-down-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Order Folders")])])],1):_vm._e(),(group.level < 3 && group.groups.length)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('PrivateDocumentGroups',{ref:"groups",refInFor:true,attrs:{"groups":group.groups,"canChangeOrders":_vm.canChangeOrders,"selected":_vm.selected,"forUpload":_vm.forUpload},on:{"showGroupDetails":function($event){return _vm.$emit('showGroupDetails', $event)},"deleteGroup":function($event){return _vm.$emit('deleteGroup', $event)},"showDocumentDetails":function($event){return _vm.$emit('showDocumentDetails', $event)},"reloadList":function($event){return _vm.$emit('reloadList')},"showAssignments":function($event){return _vm.$emit('showAssignments', $event)},"showLabels":function($event){return _vm.$emit('showLabels', $event)},"orderFolders":function($event){return _vm.$emit('orderFolders', $event)},"orderDocuments":function($event){return _vm.$emit('orderDocuments', $event)},"selected":function($event){return _vm.$emit('selected', $event)}}})],1):_vm._e(),(
            group.documents.length > 1 &&
            _vm.canChangeOrders &&
            _vm.$route.name == 'CompanyDocumentLibrary'
          )?_c('v-col',{attrs:{"align":"right","cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({directives:[{name:"blur",rawName:"v-blur"}],staticClass:"msaBlue white--text",on:{"click":function($event){return _vm.$emit('orderDocuments', group.documents)}}},on),[_c('v-icon',[_vm._v(" mdi-file-arrow-up-down-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Order Files")])])],1):_vm._e(),(group.documents.length && !_vm.forUpload)?_c('v-col',{attrs:{"cols":"12"}},[_c('PrivateDocumentsList',{attrs:{"documents":group.documents,"canChangeOrders":_vm.canChangeOrders,"selected":_vm.selected},on:{"showDocumentDetails":function($event){return _vm.$emit('showDocumentDetails', $event)},"reloadList":function($event){return _vm.$emit('reloadList')},"showAssignments":function($event){return _vm.$emit('showAssignments', $event)},"showLabels":function($event){return _vm.$emit('showLabels', $event)},"selected":function($event){return _vm.$emit('selected', $event)}}})],1):_vm._e()],1),(
          group.level < 3 &&
          group.groups.length == 0 &&
          group.documents.length == 0
        )?_c('div',{staticClass:"text-center mt-6"},[_vm._v(" (Empty Folder) ")]):_vm._e()],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }