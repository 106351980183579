<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-blur
        text
        small
        v-bind="attrs"
        v-on="on"
        :class="isMobile ? 'msaBlue white--text' : 'white msaBlue--text'"
        @click="getLabelsToAttach()"
        :width="isMobile ? '100%' : undefined"
      >
        <v-icon :color="isMobile ? 'white' : 'msaBlue'" class="pr-2">
          mdi-tag-plus-outline
        </v-icon>
        Attach Groups
      </v-btn>
    </template>
    <v-card>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col class="text-h6 pl-6 grow"> Attach Groups </v-col>
            <v-col class="shrink pr-6">
              <v-btn
                icon
                @click="
                  dialog = false;
                  selected = [];
                "
              >
                <v-icon color="white"> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
      <v-card-text :class="`mt-2 text-body-1 ${isMobile ? 'px-3' : ''}`">
        <v-row>
          <v-col>
            <v-card>
              <v-data-table
                :items="labels"
                :headers="headers"
                :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
                show-select
                checkbox-color="msaBlue"
                v-model="selected"
                class="colored-header"
              >
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-footer
        padless
        tile
        style="position: sticky; bottom: 0; z-index: 999"
        color="msaBlue"
      >
        <v-container fluid>
          <v-row>
            <v-col align="right" class="pr-6">
              <v-btn
                @click="confirm()"
                class="white msaBlue--text"
                :disabled="selected.length == 0"
              >
                confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'PrivateDocumentAttachLabels',
  props: {
    documentId: {
      type: Number,
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: 'Group',
          value: 'name',
          class: 'lightBg',
        },
      ],
      labels: [],
      selected: [],
    };
  },
  methods: {
    getLabelsToAttach() {
      const params = {
        loaderText: 'loading...',
        documentId: this.documentId,
      };

      const url = 'get-labels-for-attaching-to-private-documents?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.labels = response.data;
        })
        .catch((error) => error);
    },
    confirm() {
      const params = {
        loaderText: 'assigning...',
        documentId: this.documentId,
        labelIds: this.selected.map((item) => item.id),
      };
      const url = 'attach-labels-to-private-document?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.dialog = false;
          this.selected = [];
          this.$emit('confirmed');
        })
        .catch((error) => error);
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
