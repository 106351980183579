<template>
  <v-card>
    <v-card-title
      class="lightBg msaBlue--text py-2 text-h6"
      style="justify-content: center"
    >
      Global Settings
    </v-card-title>
    <v-card-text class="pt-4">
      <v-row align="center">
        <v-col :cols="isMobile ? 12 : false">
          <CustomizedAutoComplete
            :value="globalDocumentGroup"
            label="Select a global folder"
            hide-details
            :items="plainGroups"
            prepend-icon="mdi-folder-file-outline"
            @change="
              globalDocumentGroup = $event;
              $emit('onGlobalDocumentGroupChange', $event);
            "
          />
        </v-col>
        <v-col :class="isMobile ? '' : 'shrink'">
          <PrivateDocumentLibraryGroupManageDialog
            @folderChange="onFolderChange"
            @groupDelete="onGroupDelete"
          />
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" md="6" class="pb-0">
          <v-menu
            v-model="globalExpiryDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="globalExpiryDate"
                label="Pick a global expiration date"
                prepend-icon="mdi-calendar"
                readonly
                dense
                outlined
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="$emit('onGlobalExpiryDateChange', null)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="globalExpiryDate"
              :min="minDate"
              no-title
              scrollable
              @input="globalExpiryDateMenu = false"
              @change="$emit('onGlobalExpiryDateChange', $event)"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" md="6">
          <CustomizedAutoComplete
            deletable-chips
            :value="selectedGlobalLabels"
            :items="labels"
            hide-details
            label="Attach global groups"
            multiple
            prepend-icon="mdi-tag-multiple-outline"
            clearable
            @change="
              selectedGlobalLabels = $event;
              $emit('onGlobalLabelsChange', $event);
            "
          />
        </v-col>
        <v-col cols="12" md="6">
          <CustomizedAutoComplete
            deletable-chips
            :value="selectedGlobalEmployees"
            :items="employees"
            item-text="fullname"
            clearable
            hide-details
            label="Pick users to assign to all documents"
            multiple
            prepend-icon="mdi-account-multiple"
            @change="
              selectedGlobalEmployees = $event;
              $emit('onGlobalEmployeesChange', $event);
            "
          />
        </v-col>
        <v-col cols="12" v-if="showSendEmail">
          <v-icon class="pr-2"> mdi-email-multiple-outline </v-icon>
          <v-btn-toggle
            class="pr-2"
            active-class="msaBlue white--text"
            dense
            v-model="globalSendEmail"
            @change="$emit('onGlobalSendEmailChange', $event)"
          >
            <v-btn> no </v-btn>
            <v-btn> yes </v-btn>
          </v-btn-toggle>
          Send Email?
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import PrivateDocumentLibraryGroupManageDialog from '@/components/PrivateDocumentLibrary/PrivateDocumentLibraryGroupManageDialog.vue';

export default {
  name: 'PrivateDocumentLibraryUploadGlobalSettings',
  components: {
    PrivateDocumentLibraryGroupManageDialog,
  },
  props: {
    individualGroupChanged: {
      type: Boolean,
    },
    individualLabelsChanged: {
      type: Boolean,
    },
    individualEmployeesChanged: {
      type: Boolean,
    },
    individualExpiryDateChanged: {
      type: Boolean,
    },
    plainGroups: {
      type: Array,
    },
    hasEmployeeSelectedForAnyDocument: {
      type: Boolean,
    },
    labels: {
      type: Array,
    },
    employees: {
      type: Array,
    },
  },
  data() {
    return {
      globalDocumentGroup: null,
      globalExpiryDateMenu: false,
      globalExpiryDate: null,
      minDate: `${this.$constants.DATE_SELECTOR_RANGE.MIN}`,
      selectedGlobalLabels: [],
      selectedGlobalEmployees: [],
      globalSendEmail: 1,
      manageGroupsDialog: false,
    };
  },
  computed: {
    allLabelsSelected() {
      return this.selectedGlobalLabels.length == this.labels.length;
    },
    globalLabelSelected() {
      return this.selectedGlobalLabels.length > 0 && !this.allLabelsSelected;
    },
    selectAlliconForLabels() {
      if (this.allLabelsSelected) return 'mdi-checkbox-outline';
      if (this.globalLabelSelected) return 'mdi-minus-box-outline';
      return 'mdi-checkbox-blank-outline';
    },
    selectAlliconForEmployees() {
      if (this.allEmployeesSelected) return 'mdi-checkbox-outline';
      if (this.globalEmployeeSelected) return 'mdi-minus-box-outline';
      return 'mdi-checkbox-blank-outline';
    },
    allEmployeesSelected() {
      return this.selectedGlobalEmployees.length == this.employees.length;
    },
    globalEmployeeSelected() {
      return (
        this.selectedGlobalEmployees.length > 0 && !this.allEmployeesSelected
      );
    },
    showSendEmail() {
      return (
        this.selectedGlobalEmployees.length > 0 ||
        this.hasEmployeeSelectedForAnyDocument
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    individualGroupChanged(val) {
      if (val) {
        this.globalDocumentGroup = null;
      }
    },
    individualLabelsChanged(val) {
      if (val) {
        this.selectedGlobalLabels = [];
      }
    },
    individualEmployeesChanged(val) {
      if (val) {
        this.selectedGlobalEmployees = [];
      }
    },
    individualExpiryDateChanged(val) {
      if (val) {
        this.globalExpiryDate = null;
      }
    },
    selectedGlobalEmployees(val) {
      if (val.length > 0) {
        this.globalSendEmail = 1;
        this.$emit('onGlobalSendEmailChange', 1);
      }
    },
  },
  methods: {
    onGroupDelete(id) {
      this.$emit('groupDelete', id);
    },
    onFolderChange() {
      this.$emit('folderChange');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
