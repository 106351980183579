<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-blur
        text
        small
        v-bind="attrs"
        v-on="on"
        :class="isMobile ? 'msaBlue white--text' : 'white msaBlue--text'"
        :width="isMobile ? '100%' : undefined"
        :disabled="!document.isPublished"
      >
        <v-icon :color="isMobile ? 'white' : 'msaBlue'" class="pr-2">
          mdi-account-plus
        </v-icon>
        Assign Document
      </v-btn>
    </template>
    <v-card>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col class="text-h6 pl-6 grow"> Assign to Users </v-col>
            <v-col class="shrink pr-6">
              <v-btn icon @click="close()">
                <v-icon color="white"> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
      <v-card-text :class="`mt-2 text-body-1 ${isMobile ? 'px-3' : ''}`">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="filters.search"
              placeholder="Search by employee name..."
              hide-details
              dense
              outlined
              clearable
              prepend-icon="mdi-magnify"
              @click:clear="resetSearch()"
            />
          </v-col>
          <v-col cols="12" md="6">
            <CustomizedAutoComplete
              deletable-chips
              :value="filters.selectedLabels"
              :items="labels"
              prepend-icon="mdi-tag"
              label="Filter by group"
              hide-details
              clearable
              multiple
              @change="filters.selectedLabels = $event"
            />
          </v-col>
          <v-col cols="12" md="6">
            <CustomizedAutoComplete
              deletable-chips
              :value="filters.selectedSupervisors"
              :items="supervisors"
              item-text="fullname"
              prepend-icon="mdi-account-supervisor"
              label="Filter by supervisor"
              hide-details
              clearable
              multiple
              @change="filters.selectedSupervisors = $event"
            />
          </v-col>
          <v-col cols="12" v-if="selected.length > 0"> </v-col>
          <v-col cols="12" v-if="selected.length > 0">
            <v-icon class="pr-2"> mdi-email-multiple-outline </v-icon>
            <v-btn-toggle
              v-model="sendEmail"
              active-class="msaBlue white--text"
              class="pr-2"
              dense
              mandatory
            >
              <v-btn :value="1"> yes </v-btn>
              <v-btn :value="0"> no </v-btn>
            </v-btn-toggle>
            Send Email?
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-data-table
                :items="filteredEmployees"
                :headers="headers"
                :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
                show-select
                checkbox-color="msaBlue"
                v-model="selected"
                class="colored-header"
              >
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-footer
        padless
        tile
        style="position: sticky; bottom: 0; z-index: 999"
        color="msaBlue"
      >
        <v-container fluid>
          <v-row>
            <v-col align="right" class="pr-6">
              <v-btn
                @click="confirm()"
                class="white msaBlue--text"
                :disabled="selected.length == 0"
              >
                confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'PrivateDocumentNewAssignments',
  props: {
    document: {
      type: Object,
    },
  },
  computed: {
    isSelected() {
      return (item) => {
        return this.selected.includes(item);
      };
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    filteredEmployees() {
      let result = this.employees;

      if (this.filters.search && this.filters.search.trim().length) {
        result = result.filter((e) =>
          e.fullname
            .toLowerCase()
            .includes(this.filters.search.trim().toLowerCase()),
        );
      }

      if (this.filters.selectedLabels.length) {
        result = result.filter((e) =>
          this.filters.selectedLabels.some((l) =>
            e.labelIds.some((el) => l == el),
          ),
        );
      }

      if (this.filters.selectedSupervisors.length) {
        result = result.filter((e) =>
          this.filters.selectedSupervisors.some((l) =>
            e.supervisorIds.some((el) => l == el),
          ),
        );
      }

      return result;
    },
  },
  data() {
    return {
      dialog: false,
      employees: [],
      headers: [
        {
          text: 'Name',
          value: 'fullname',
          class: 'lightBg',
        },
        {
          text: 'Username',
          value: 'username',
          class: 'lightBg',
        },
      ],
      selected: [],
      today: this.$helpers.getToday(),
      labels: [],
      supervisors: [],
      filters: {
        selectedLabels: [],
        selectedSupervisors: [],
        search: '',
      },
      sendEmail: 0,
    };
  },
  methods: {
    getEmployeesToAssign() {
      const params = {
        loaderText: 'loading...',
        documentId: this.document.id,
      };

      const url = 'get-data-to-assign?format=json';

      this.$axios.post(url, params).then((response) => {
        response.data.employees.forEach((employee) => {
          employee.sendEmail = false;
        });
        this.employees = response.data.employees;
        this.labels = response.data.labels;
        this.supervisors = response.data.supervisors;
      });
    },
    confirm() {
      const users = this.selected.map((item) => ({
        id: item.id,
      }));

      const params = {
        loaderText: 'assigning...',
        documentId: this.document.id,
        users: users,
        sendEmail: this.sendEmail,
      };
      const url = 'assign-document-to-employees?format=json';

      this.$axios.post(url, params).then(() => {
        this.close();
        this.$emit('confirmed');
      });
    },
    close() {
      this.dialog = false;
      this.search = null;
      this.selected = [];
      this.selectedLabel = null;
      this.selectedSupervisor = null;
      this.sendEmail = 1;

      this.resetSearch();
      this.resetDropDownFilters();
    },
    resetSearch() {
      // this is to reset search to empty string instead of null
      // so it needs to wait for a flash when user clicks clear
      setTimeout(() => {
        this.filters.search = '';
      }, 100);
    },
    resetDropDownFilters() {
      this.filters.selectedLabels = [];
      this.filters.selectedSupervisors = [];
    },
  },
};
</script>
<style>
.colored-header .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
