<template>
  <v-dialog v-model="dialog" width="1200px" persistent style="height: 70vh">
    <v-card>
      <v-card-title class="msaBlue white--text"> File Details </v-card-title>
      <v-card-subtitle class="msaBlue white--text">
        Last updated By {{ edited.updatedByName }} on
        {{ edited.updatedAt | filterAsLocalDate }}
      </v-card-subtitle>
      <v-card-text :class="`pt-4 ${isMobile ? 'px-2' : ''}`">
        <v-row>
          <v-col>
            <v-form v-model="isValid">
              <v-text-field
                v-model="edited.name"
                dense
                outlined
                label="Name"
                counter
                maxlength="1000"
                :rules="[(v) => !!v || 'Name is required']"
              >
              </v-text-field>
            </v-form>
          </v-col>
        </v-row>
        <v-row dense v-if="!isMobile">
          <v-col>
            <v-text-field
              v-model="edited.originalFilename"
              outlined
              dense
              readonly
              label="File"
            >
            </v-text-field>
          </v-col>
          <v-col class="shrink" :key="reloadUploader">
            <v-btn class="msaBlue white--text" @click="$refs.uploader.click()">
              Replace File
            </v-btn>
            <input
              type="file"
              ref="uploader"
              class="d-none"
              accept=".pdf, .doc, .docx, .xls, .xlsx"
              @change="replaceDocument($event)"
            />
          </v-col>
        </v-row>
        <v-row dense v-if="isMobile">
          <v-col cols="12">
            <v-text-field
              v-model="edited.originalFilename"
              outlined
              dense
              readonly
              hide-details
              label="File"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn
              class="msaBlue white--text"
              @click="$refs.uploader.click()"
              width="100%"
            >
              Replace File
            </v-btn>
            <input
              type="file"
              ref="uploader"
              class="d-none"
              accept=".pdf, .doc, .docx, .xls, .xlsx"
              @change="replaceDocument($event)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col :class="isMobile ? 'pt-4' : ''">
            <CustomizedAutoComplete
              :value="edited.privateLibraryGroupId"
              label="Folder"
              hide-details
              :items="groups"
              @change="edited.privateLibraryGroupId = $event"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              v-model="expiryDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="edited.expiryDate"
                  label="Expiration date"
                  readonly
                  dense
                  outlined
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="edited.expiryDate"
                :min="minDate"
                @input="expiryDateMenu = false"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <PublishButtonGroup
              :is-published="edited.isPublished"
              @click="edited.isPublished = $event"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="msaBlue--text text-h6">
            <v-icon color="msaBlue">mdi-history</v-icon>
            History
          </v-col>
          <v-col>
            <DocumentHistory :histories="document.histories" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="font-weight-bold"
          @click="dialog = false"
          :class="(disableSave ? 'msaBlue' : 'red') + '--text'"
        >
          {{ disableSave ? 'Close' : 'Cancel' }}
        </v-btn>

        <v-btn
          class="msaBlue white--text"
          :disabled="disableSave"
          @click="saveDetails()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DocumentHistory from '@/components/DocumentHistory.vue';
import PublishButtonGroup from '@/components/PublishButtonGroup';
export default {
  name: 'PrivateDocumentDetails',
  props: {
    documentId: {
      type: Number,
    },
    groups: {
      type: Array,
    },
  },
  components: {
    DocumentHistory,
    PublishButtonGroup,
  },
  data() {
    return {
      dialog: false,
      document: {},
      edited: {},
      expiryDateMenu: false,
      isValid: true,
      minDate: this.$constants.DATE_SELECTOR_RANGE.MIN,
      reloadUploader: 9999,
      file: '',
    };
  },
  watch: {
    dialog(onOpen) {
      if (!onOpen) {
        this.$emit('close');
      }
    },
  },
  computed: {
    disableSave() {
      if (!this.isValid) {
        return true;
      }

      if (
        JSON.stringify(this.edited) == JSON.stringify(this.document) &&
        !this.file
      ) {
        return true;
      }

      return false;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showPublishWarning() {
      return (
        (!this.document.isPublished &&
          this.edited.isPublished &&
          this.edited.mimeType != 'application/pdf') ||
        (this.edited.mimeType != 'application/pdf' && this.edited.isPublished)
      );
    },
  },
  methods: {
    async getDetail() {
      const params = {
        loaderText: 'Loading...',
        id: this.documentId,
      };
      const url = 'get-private-library-document-detail?format=json';

      await this.$axios.post(url, params).then((response) => {
        this.document = response.data;
        this.document.expiryDate = this.$options.filters.filterAsLocalDate(
          this.document.expiryDate,
        );

        this.edited = JSON.parse(JSON.stringify(this.document));
      });
    },
    async replaceDocument(e) {
      const file = e.target.files[0];

      if (!file) {
        return;
      }

      try {
        await this.$helpers.processFile(
          file,
          this.$constants.ACCEPTED_MIME_TYPES.DOCUMENT,
          true,
        );
      } catch (error) {
        if (error.message) {
          this.$root.showMessage(
            'Error',
            error.message,
            () => null,
            null,
            'ok',
          );
        }
        return;
      }

      this.file = file;
      this.edited.originalFilename = file.name;
      this.edited.mimeType = file.type;
    },
    saveDetails() {
      const confirm = () => {
        const params = new FormData();
        params.append('file', this.file);
        params.append('mimeType', this.edited.mimeType);
        params.append('originalFilename', this.edited.originalFilename);
        params.append('name', this.edited.name.trim());
        params.append('groupId', this.edited.privateLibraryGroupId);
        params.append(
          'expiryDate',
          this.edited.expiryDate
            ? this.$helpers.formatDateTimeToUTC(this.edited.expiryDate)
            : '',
        );
        params.append('isPublished', this.edited.isPublished);
        params.append('id', this.documentId);
        params.append('jwtToken', this.$store.getters.jwtToken);
        params.append('loaderText', 'Saving...');
        const headers = {
          'Content-Type': 'multipart/form-data',
        };

        const url = 'save-private-library-document-detail';

        this.$axios
          .post(url, params, headers)
          .then(() => {
            this.dialog = false;
            this.$emit('reloadList');
          })
          .catch((error) => error);
      };
      if (this.showPublishWarning) {
        this.$root.showMessage(
          'Warning',
          'This file will be converted to PDF after publish.',
          () => confirm(),
          () => null,
          'confirm',
          'cancel',
        );
      } else {
        confirm();
      }
    },
    show() {
      this.$nextTick(async () => {
        await this.getDetail();
        this.dialog = true;
      });
    },
  },
};
</script>
