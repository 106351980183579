<template>
  <v-dialog v-model="dialog" width="900" persistent>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col>
            <v-row dense>
              <v-col cols="12"> Manage Attached Groups </v-col>
              <v-col
                v-if="!isPublished"
                cols="12"
                class="text-body-2 font-weight-bold"
              >
                Note: This document will be published after attach to new
                groups.
              </v-col>
            </v-row>
          </v-col>
          <v-col class="shrink" v-if="!isMobile">
            <PrivateDocumentAttachLabels
              :documentId="documentId"
              @confirmed="getAttachedLabels()"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text :class="`mt-2 text-body-1 ${isMobile ? 'px-2' : ''}`">
        <v-row v-if="isMobile">
          <v-col class="pb-6">
            <PrivateDocumentAttachLabels
              :documentId="documentId"
              @confirmed="getAttachedLabels()"
            />
          </v-col>
        </v-row>
        <v-card>
          <v-data-table
            :items="attachedLabels"
            :headers="headers"
            no-data-text="No attached groups"
            must-sort
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-blur
                    text
                    small
                    v-on="{ ...tooltip }"
                    @click="detach(item.id)"
                  >
                    <v-icon color="red"> mdi-tag-off-outline </v-icon>
                  </v-btn>
                </template>
                <span>Detach</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="dialog = false"
          text
          class="msaBlue--text font-weight-bold"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import PrivateDocumentAttachLabels from '@/components/PrivateDocumentLibrary/PrivateDocumentAttachLabels.vue';
export default {
  name: 'PrivateDocumentManageLabels',
  components: {
    PrivateDocumentAttachLabels,
  },
  props: {
    documentId: {
      type: Number,
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    'attachedLabels.length'(newVal, oldVal) {
      // when no label is attached or the first label gets attached
      // we need to reload list for the unpublish warning to display properly
      if (newVal == 0 || oldVal == 0) {
        this.$emit('reloadList');
      }
    },
  },
  data() {
    return {
      dialog: false,
      attachedLabels: [],
      headers: [
        {
          text: 'Group',
          value: 'name',
          class: 'lightBg',
        },
        {
          text: 'User Count',
          value: 'employeeCount',
          class: 'lightBg',
          align: 'center',
          width: '200px',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          class: 'lightBg',
          width: '82px',
        },
      ],
      isPublished: false,
    };
  },
  methods: {
    getAttachedLabels() {
      const params = {
        loaderText: 'Loading...',
        documentId: this.documentId,
      };
      const url = 'get-private-library-document-attached-labels?format=json';

      return this.$axios.post(url, params).then((response) => {
        this.attachedLabels = response.data.labels;
        this.isPublished = response.data.isPublished;
      });
    },
    detach(labelId) {
      const params = {
        loaderText: 'Loading...',
        documentId: this.documentId,
        labelId: labelId,
      };
      const url = 'detach-label-from-private-library-document?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.getAttachedLabels();
        })
        .catch((error) => error);
    },
    show() {
      this.$nextTick(() => {
        this.getAttachedLabels()
          .then(() => (this.dialog = true))
          .catch((error) => error);
      });
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
