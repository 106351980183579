<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text">
          <v-row>
            <v-col class="grow"> Filters </v-col>
            <v-col class="shrink">
              <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row dense>
            <v-col cols="12"> File Name </v-col>
            <v-col>
              <v-text-field
                v-model="filters.search"
                outlined
                hide-details
                placeholder="Enter file name"
                dense
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12"> Folder </v-col>
            <v-col cols="12">
              <CustomizedAutoComplete
                :value="filters.groupIds"
                label="Select folder(s)"
                hide-details
                :items="groups"
                multiple
                clearable
                deletable-chips
                @change="filters.groupIds = $event"
              />
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="12"> Created Date </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="createdFromMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.createdFrom"
                    label="From"
                    readonly
                    outlined
                    dense
                    clearable
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.createdFrom"
                  no-title
                  @input="createdFromMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="createdToMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.createdTo"
                    label="To"
                    outlined
                    dense
                    clearable
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.createdTo"
                  no-title
                  :min="createdToMin"
                  @input="createdToMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="12"> Expiry Date </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="expiredFromMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.expiredFrom"
                    label="From"
                    readonly
                    outlined
                    dense
                    clearable
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.expiredFrom"
                  no-title
                  @input="expiredFromMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="expiredToMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.expiredTo"
                    label="To"
                    outlined
                    dense
                    clearable
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.expiredTo"
                  no-title
                  :min="expiredToMin"
                  @input="expiredToMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense v-if="$route.name == 'CompanyDocumentLibrary'">
            <v-col cols="12"> File Type </v-col>
            <v-col>
              <v-select
                v-model="filters.docTypes"
                outlined
                dense
                hide-details
                :items="docTypes"
                placeholder="Select file type(s)"
                clearable
                multiple
                chips
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense v-if="$route.name == 'CompanyDocumentLibrary'">
            <v-col cols="12"> Published </v-col>
            <v-col>
              <v-btn-toggle
                v-model="filters.isPublished"
                mandatory
                dense
                active-class="msaBlue white--text"
                style="width: 100%"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn :value="0" v-on="on" :style="{ width: '33%' }">
                      <v-icon color="orange"> mdi-eye-off </v-icon>
                    </v-btn>
                  </template>
                  <span>Unpublish</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn :value="1" v-on="on" :style="{ width: '33%' }">
                      <v-icon color="green"> mdi-eye </v-icon>
                    </v-btn>
                  </template>
                  <span>Publish</span>
                </v-tooltip>
                <v-btn style="width: 34%" class="text-capitalize" :value="2">
                  All
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row dense v-if="$route.name == 'CompanyDocumentLibrary'">
            <v-col cols="12"> Deleted </v-col>
            <v-col>
              <v-btn-toggle
                v-model="filters.deleted"
                mandatory
                dense
                active-class="msaBlue white--text"
                style="width: 100%"
              >
                <v-btn style="width: 33%" class="text-capitalize" :value="0">
                  deleted
                </v-btn>
                <v-btn style="width: 33%" class="text-capitalize" :value="1">
                  Not deleted
                </v-btn>
                <v-btn style="width: 34%" class="text-capitalize" :value="2">
                  All
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="clear()"> clear </v-btn>
          <v-btn class="msaBlue white--text" @click="apply()"> apply </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="isFilterApplied ? 'orange' : 'msaBlue'"
          dark
          v-blur
          v-bind="attrs"
          v-on="on"
          @click="show"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </template>
      <span>Filter</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'PrivateDocumentLibraryFilter',
  props: {
    groups: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      filters: JSON.parse(
        JSON.stringify(this.$constants.PRIVATE_DOCUMENT_LIBRARY_FILTERS),
      ),
      original: { ...this.$constants.PRIVATE_DOCUMENT_LIBRARY_FILTERS },
      createdFromMenu: false,
      createdToMenu: false,
      expiredFromMenu: false,
      expiredToMenu: false,
      docTypes: ['Word', 'PDF', 'Excel'],
      isFilterApplied: false,
    };
  },
  computed: {
    createdToMin() {
      return this.filters.createdFrom == '' || this.filters.createdFrom == null
        ? undefined
        : this.filters.createdFrom;
    },
    expiredToMin() {
      return this.filters.expiredFrom == '' || this.filters.expiredFrom == null
        ? undefined
        : this.filters.expiredFrom;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    updateFilterButtonState() {
      this.isFilterApplied =
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.PRIVATE_DOCUMENT_LIBRARY_FILTERS);
    },
    show() {
      this.orginal = { ...this.filters };
      this.dialog = true;
    },
    close() {
      this.filters = { ...this.orginal };
      this.dialog = false;
    },
    clear() {
      this.filters = JSON.parse(
        JSON.stringify(this.$constants.PRIVATE_DOCUMENT_LIBRARY_FILTERS),
      );
      this.apply(true);
    },
    apply(isClearEvent) {
      this.filters = this.$helpers.fixFilterSpaces(
        this.$constants.PRIVATE_DOCUMENT_LIBRARY_FILTERS,
        this.filters,
      );
      this.updateFilterButtonState();
      if (isClearEvent) {
        this.filters['isClearEvent'] = true;
      }
      this.$emit('applyFilters', this.filters);
      this.dialog = false;
    },
    setFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.updateFilterButtonState();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
